import { graphql } from "gatsby";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useI18next } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import AccountRegister from "../../components/AccountRegister";
import LayoutFloating from "../../components/LayoutFloating";
import { regionRequest } from "../../actions";

const Register = (props) => {
  const {
    region_data: { unique_region_text, active_language },
    region_loading,
    dispatch,
  } = props;
  const { language } = useI18next();

  useEffect(() => {
    if (
      (!region_loading && !unique_region_text) ||
      language !== active_language
    ) {
      dispatch(regionRequest(language));
    }
  }, [unique_region_text, region_loading, language, dispatch, active_language]);

  return (
    <Layout pageName="account-register" showHeader={false}>
      <LayoutFloating title={unique_region_text?.siteTitle ?? ""}>
        <AccountRegister {...props} />
      </LayoutFloating>
    </Layout>
  );
};

export default connect((state) => state)(Register);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
